import { SVGProps, memo } from "react";

const SvgRuler = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.3359 7.33188L21.3359 9.33188M15.3359 11.3319L17.3359 13.3319M11.3359 15.3319L13.3359 17.3319M7.33589 19.3319L9.33589 21.3319M3.42342 23.4195L8.58159 28.5777C8.8456 28.8417 8.97761 28.9737 9.12983 29.0232C9.26372 29.0667 9.40795 29.0667 9.54185 29.0232C9.69407 28.9737 9.82607 28.8417 10.0901 28.5777L28.5816 10.0862C28.8456 9.82217 28.9776 9.69016 29.0271 9.53794C29.0706 9.40405 29.0706 9.25982 29.0271 9.12592C28.9776 8.9737 28.8456 8.84169 28.5816 8.57768L23.4234 3.41951C23.1594 3.1555 23.0274 3.02349 22.8752 2.97404C22.7413 2.93053 22.5971 2.93053 22.4632 2.97404C22.3109 3.02349 22.1789 3.1555 21.9149 3.41951L3.42342 21.911C3.15941 22.175 3.0274 22.307 2.97794 22.4593C2.93444 22.5931 2.93444 22.7374 2.97794 22.8713C3.0274 23.0235 3.15941 23.1555 3.42342 23.4195Z"
      stroke="#343131"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgRuler);
export default Memo;
