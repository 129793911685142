import { useTranslation } from "next-i18next";
import PlaceholderIcon from "public/icons/placeholder-dark.svg";
import { FC } from "react";

import { Button } from "src/components/Common";

import Reason from "./Reason";
import subscriptionStyle from "./Subscription.module.scss";

const Subscription: FC = () => {
  const { t } = useTranslation();

  const reasons = [
    {
      icon: PlaceholderIcon,
      title: t("product:subscription.choose.title"),
      desc: t("product:subscription.choose.desc"),
    },
    {
      icon: PlaceholderIcon,
      title: t("product:subscription.deliver.title"),
      desc: t("product:subscription.deliver.desc"),
    },
    {
      icon: PlaceholderIcon,
      title: t("product:subscription.routine.title"),
      desc: t("product:subscription.routine.desc"),
    },
    {
      icon: PlaceholderIcon,
      title: t("product:subscription.control.title"),
      desc: t("product:subscription.control.desc"),
    },
  ];

  return (
    <section className={subscriptionStyle.container}>
      <section className={subscriptionStyle.wrapper}>
        <h1 className={subscriptionStyle.title}>
          {t("product:subscription.subscription-guide")}
        </h1>

        <div className={subscriptionStyle.reasons}>
          {reasons.map((reason, index) => (
            <Reason
              key={index}
              icon={reason.icon}
              title={`${index + 1}. ${reason.title}`}
              desc={reason.desc}
            />
          ))}
        </div>

        <Button
          title={t("product:subscription.detail")}
          type="outline"
          onClick={() => null}
        />
      </section>
    </section>
  );
};

export default Subscription;
