import { Box } from "@mui/material";
import { SxProps } from "@mui/material/styles";

type DotButtonProps = {
  selected: boolean;
  onClick: () => void;
  dotsColor?: "dark" | "light";
};

export const DotButton = ({
  selected,
  onClick,
  dotsColor = "dark",
}: DotButtonProps) => {
  const dotStyle = {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    border: "none",
    outline: "none",
    cursor: "pointer",
  };

  const carouselDotStyle: SxProps = () => ({
    ...dotStyle,
    background:
      dotsColor === "dark" ? "rgba(0, 0, 0, 0.12)" : "rgba(255, 255, 255, 0.3)",
  });
  const carouselActiveDotStyle: SxProps = () => ({
    ...dotStyle,
    background: dotsColor === "dark" ? "#343131" : "#FFFFFF",
  });

  return (
    <Box
      sx={selected ? carouselActiveDotStyle : carouselDotStyle}
      component="button"
      type="button"
      onClick={onClick}
    />
  );
};
