import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Image from "next/image";
import { useTranslation } from "next-i18next";

import PricePolicyMobileImage from "public/images/price-policy-mobile.png";
import PricePolicyImage from "public/images/price-policy.png";

const PricePolicy = () => {
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.down("md"));

  const { t } = useTranslation();

  return (
    <Box py={12} bgcolor={theme.palette.background.white}>
      <Container>
        <Box>
          <Typography component="p" variant="mobileH1" mb={4}>
            {t("price-policy:radical-transparency.title")}
          </Typography>
          <Typography component="p" variant="desktopBody1" mb={6}>
            {t("price-policy:radical-transparency.description")}
          </Typography>

          <Image
            src={breakpoint ? PricePolicyMobileImage : PricePolicyImage}
            alt="Price Policy"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default PricePolicy;
