import Image from "next/image";
import { useTranslation } from "next-i18next";
import placeholderImage from "public/images/placeholder.png";
import { FC } from "react";

import { Button } from "src/components/Common";

import trialPackStyle from "./TrialPack.module.scss";

const TrialPack: FC = () => {
  const { t } = useTranslation();
  return (
    <section className={trialPackStyle.container}>
      <section className={trialPackStyle.wrapper}>
        <div className={trialPackStyle.details}>
          <h3>Deneme Paketi</h3>
          <p>
            Abone olmadan önce sizi biraz daha yakından tanımak istiyorum” mu
            diyorsun? beije Ped ve Tampon Deneme Paketleri, tam da bu yüzden,
            senin için tasarlandı. İstediğin Deneme Paketi’ni şimdi sipariş et,
            beije’i özgürce deneyimle.
          </p>

          <div className={trialPackStyle.button}>
            <Button
              title={t("product:buy")}
              type="primary"
              onClick={() => null}
            />
          </div>
        </div>

        <div className={trialPackStyle.image}>
          <Image
            src={placeholderImage}
            alt="Image Placeholder"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </div>
      </section>
    </section>
  );
};

export default TrialPack;
