import { Box } from "@mui/material";
import { SxProps } from "@mui/material/styles";
import useEmblaCarousel, { EmblaOptionsType } from "embla-carousel-react";
import { useState, useEffect, useCallback, ReactElement } from "react";

import { DotButton } from "./CarouselDotsButtons";

type EmblaCarouselProps = {
  children?: ReactElement | ReactElement[];
  options?: EmblaOptionsType;
  onChangeSlide?: () => void;
  dotsColor?: "dark" | "light";
  className?: string;
};

const EmblaCarousel = ({
  children,
  options,
  onChangeSlide,
  dotsColor,
  className,
}: EmblaCarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
    if (onChangeSlide) onChangeSlide();
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on("select", onSelect);
    emblaApi.on("reInit", onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  const carouselViewportStyle: SxProps = () => ({
    overflow: "hidden",
  });

  const carouselContainerStyle: SxProps = () => ({
    display: "flex",
    flexDirection: "row",
    height: "auto",
    ml: "calc(var(1rem) * -1)",
  });

  const carouselDotsContainerStyle: SxProps = () => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
    mt: 2,
  });

  return (
    <>
      <Box className={className}>
        <Box sx={carouselViewportStyle} ref={emblaRef}>
          <Box sx={carouselContainerStyle}>{children}</Box>
        </Box>
      </Box>

      <Box sx={carouselDotsContainerStyle}>
        {scrollSnaps.map((_, index) => (
          <DotButton
            key={index}
            selected={index === selectedIndex}
            onClick={() => scrollTo(index)}
            dotsColor={dotsColor}
          />
        ))}
      </Box>
    </>
  );
};

export default EmblaCarousel;
