import { Box, Typography, Container } from "@mui/material";
import { useTheme, SxProps } from "@mui/material/styles";
/* eslint-disable jsx-a11y/media-has-caption */
import Image from "next/image";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import Carousel from "src/components/Common/Carousel";

import { useGetProductTypesQuery } from "src/store/apis";

const ProductCertificates = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const router = useRouter();
  const { data: stateProductTypes } = useGetProductTypesQuery();

  const productType = useMemo(
    () =>
      stateProductTypes?.find(
        (type) => type.pageIdentifier === router.query.pageIdentifier
      ),
    [router.query.pageIdentifier, stateProductTypes]
  );

  const wrapperStyle: SxProps = () => ({
    py: 9,
    width: "100%",
    backgroundColor: theme.palette.background.white,
  });

  const certificates = [
    {
      imageSrc: "/images/certificates/GOTS.png",
      title: t("product:product-certificates.gots.title"),
      description: t("product:product-certificates.gots.description"),
      products: ["pads", "pantyliner", "tampons"],
    },
    {
      imageSrc: "/images/certificates/vegan-society.png",
      title: t("product:product-certificates.vegan-society.title"),
      description: t("product:product-certificates.vegan-society.description"),
      products: ["pads", "pantyliner", "tampons", "cup"],
    },
    {
      imageSrc: "/images/certificates/FSC.png",
      title: t("product:product-certificates.fsc.title"),
      description: t("product:product-certificates.fsc.description"),
      products: ["pads", "pantyliner", "tampons"],
    },
    {
      imageSrc: "/images/certificates/oeko-tex.png",
      title: t("product:product-certificates.oeko-tex.title"),
      description: t("product:product-certificates.oeko-tex.description"),
      products: ["pads", "pantyliner", "tampons"],
    },
    {
      imageSrc: "/images/certificates/CE.png",
      title: t("product:product-certificates.ce.title"),
      description: t("product:product-certificates.ce.description"),
      products: ["pads", "pantyliner", "tampons", "cup"],
    },
    {
      imageSrc: "/images/certificates/OBPF.png",
      title: t("product:product-certificates.obpf.title"),
      description: t("product:product-certificates.obpf.description"),
      products: ["pads", "pantyliner", "tampons"],
    },
    {
      imageSrc: "/images/certificates/FDA.png",
      title: t("product:product-certificates.fda.title"),
      description: t("product:product-certificates.fda.description"),
      products: ["cup"],
    },
  ];

  return (
    <Box sx={wrapperStyle}>
      <Container>
        <Typography component="h2" variant="desktopH2" mb={6}>
          {t("product:product-certificates.title")}
        </Typography>
        <Carousel options={{ loop: true }}>
          {certificates
            .filter((item) =>
              productType?.pageIdentifier
                ? item.products.includes(productType.pageIdentifier)
                : true
            )
            .map((item, index) => (
              <Box
                key={index}
                sx={{
                  flex: { xs: "0 0 50%", md: `0 0 20%` },
                  mb: 2,
                  mx: 2,
                }}
              >
                <Image
                  src={item.imageSrc}
                  alt={item.title}
                  width={100}
                  height={100}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
                <Box mt={3}>
                  <Typography component="h4" variant="desktopSubtitle1" mb={1}>
                    {item.title}
                  </Typography>
                  <Typography component="p" variant="desktopCaption">
                    {item.description}
                  </Typography>
                </Box>
              </Box>
            ))}
        </Carousel>
      </Container>
    </Box>
  );
};

export default ProductCertificates;
