import { Box, Typography, Container } from "@mui/material";
import { useTheme, SxProps } from "@mui/material/styles";
import { useTranslation } from "next-i18next";
import Carousel from "src/components/Common/Carousel";

import { ProductTestimonial } from "src/types/Testimonials";
import { getRandomElements } from "src/utils";

type Props = {
  data: ProductTestimonial[];
};

export const ProductTestimonials = ({ data }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const wrapperStyle: SxProps = () => ({
    pt: 10,
    pb: 14,
    width: "100%",
    backgroundColor: theme.palette.common.purple.main,
    textAlign: "center",
    color: theme.palette.primary.contrastText,
  });

  // randomly pick up 6 testimonials
  data = getRandomElements(data, 6);

  return (
    <Box id="reviews" sx={wrapperStyle}>
      <Container>
        <Typography component="h2" variant="desktopH2" mb={6}>
          {t("product:product-testimonials.title")}
        </Typography>
        <Carousel options={{ loop: true }} dotsColor="light">
          {data.map((item, index) => (
            <Box
              key={index}
              sx={{ flex: { xs: "0 0 90%", md: "0 0 33%" }, mb: 2, mx: 2 }}
            >
              <Typography component="p" variant="desktopBody2" mb={2}>
                {item.review}
              </Typography>
              <Typography component="h6" variant="desktopH6">
                {item.name}, {item.age}
              </Typography>
              {/* <Typography component="p" variant="desktopCaption">
                  {item.duration}{" "}
                  {t("product:product-testimonials.months-subscriber")}
                </Typography> */}
            </Box>
          ))}
        </Carousel>
      </Container>
    </Box>
  );
};
