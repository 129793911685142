const tableData = {
  pads: {
    title: "pad-types",
    data: [
      ["standard-pad", "245 mm", "100 mL", "6 gr"],
      ["super-pad", "290 mm", "125 mL", "7.5 gr"],
      ["super-plus-pad", "330 mm", "150 mL", "10 gr"],
    ],
  },
  pantyliner: {
    title: "pantyliner-types",
    data: [
      ["standard-pantyliner", "155 mm", "10 mL", "1.8 gr"],
      ["super-pantyliner", "180 mm", "55 mL", "3 gr"],
    ],
  },
  tampons: {
    title: "tampon-types",
    data: [
      [["mini-tampon", "mini-tampon-description"], "35 mm", "7.5 ml", "3.4 gr"],
      [
        ["standard-tampon", "standard-tampon-description"],
        "42 mm",
        "10 ml",
        "4.2 gr",
      ],
      // [["super-tampon", "super-tampon-description"], "50 mm", "15 ml", "4 gr"],
    ],
  },
  cup: {
    title: "menstrual-cups-types",
    data: [
      ["standard-menstrual-cup", "73 mm", "26 ml", "-"],
      ["super-menstrual-cup", "80 mm", "30 ml", "-"],
    ],
  },
};

export default tableData;
