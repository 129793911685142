import { SVGProps, memo } from "react";

const SvgDrop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.9974 28.6654C18.4728 28.6654 20.8467 27.682 22.5971 25.9317C24.3474 24.1814 25.3307 21.8074 25.3307 19.332C25.3307 16.6654 23.9974 14.132 21.3307 11.9987C18.6641 9.86536 16.6641 6.66536 15.9974 3.33203C15.3307 6.66536 13.3307 9.86536 10.6641 11.9987C7.9974 14.132 6.66406 16.6654 6.66406 19.332C6.66406 21.8074 7.64739 24.1814 9.39773 25.9317C11.1481 27.682 13.522 28.6654 15.9974 28.6654Z"
      stroke="#343131"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgDrop);
export default Memo;
