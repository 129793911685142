import {
  Box,
  Typography,
  Button,
  Container,
  useMediaQuery,
} from "@mui/material";
import { useTheme, SxProps } from "@mui/material/styles";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";

import { useGetFaqsQuery } from "src/store/apis/Faq";
import { createFilterQuery } from "src/utils/packet";

import { Accordions } from "./Accordions";

type FaqsProps = {
  title: string;
  filterKey: string;
  filterValue?: string | string[];
};

const Faqs = ({ title, filterKey, filterValue }: FaqsProps) => {
  const theme = useTheme();
  const mediumBreakpoint = useMediaQuery(theme.breakpoints.up("md"));
  const { t, i18n } = useTranslation();

  const { data: allFaqs } = useGetFaqsQuery(
    {
      filter: filterValue
        ? createFilterQuery([
            {
              key: filterKey,
              values: Array.isArray(filterValue) ? filterValue : [filterValue],
            },
          ])
        : undefined,
      limit: 5,
    },
    {
      skip: !filterValue,
    }
  );

  const [faqs, setFaqs] = useState<
    {
      _id: string;
      question: string;
      answer: string;
    }[]
  >([]);

  useEffect(() => {
    if (allFaqs?.data?.length) {
      const FAQListOnSelectedLanguage = allFaqs?.data?.map((item) => ({
        _id: item._id,
        question: item.question[i18n.language],
        answer: item.answer[i18n.language],
      }));
      setFaqs(FAQListOnSelectedLanguage);
    }
  }, [allFaqs, i18n.language]);

  const wrapperStyle: SxProps = () => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    background: theme.palette.background.paper,
    gap: {
      lg: 6,
      md: 5,
      sm: 5,
      xs: 5,
    },
    paddingY: {
      lg: 12,
      md: 5,
      sm: 5,
      xs: 5,
    },
    paddingX: {
      lg: 18,
      md: 3.375,
      sm: 3.375,
      xs: 3.375,
    },
  });

  const containerStyle: SxProps = () => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: {
      lg: 6,
      md: 5,
      sm: 5,
      xs: 5,
    },
  });

  return faqs.length ? (
    <Box id="details" sx={wrapperStyle}>
      <Container sx={containerStyle}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={3}
        >
          <Typography
            textAlign="center"
            variant={mediumBreakpoint ? "desktopH2" : "mobileH2"}
          >
            {title}
          </Typography>
        </Box>
        <Accordions items={faqs} />
        <Link href="/know-us/faqs">
          <Button
            fullWidth={!mediumBreakpoint}
            size="large"
            variant="contained"
          >
            {t("product:all-questions")}
          </Button>
        </Link>
      </Container>
    </Box>
  ) : null;
};

export default Faqs;
