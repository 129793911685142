import { SVGProps, memo } from "react";

const SvgScales01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 32}
    height={props.height || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.66406 22.6667V22.4664C2.66406 22.0444 2.66406 21.8334 2.69634 21.6279C2.725 21.4454 2.77254 21.2663 2.83821 21.0936C2.91218 20.8991 3.01686 20.7159 3.22623 20.3495L7.9974 12M2.66406 22.6667C2.66406 25.6122 5.05188 28 7.9974 28C10.9429 28 13.3307 25.6122 13.3307 22.6667M2.66406 22.6667V22.4C2.66406 22.0266 2.66406 21.8399 2.73672 21.6973C2.80064 21.5719 2.90263 21.4699 3.02807 21.406C3.17068 21.3333 3.35736 21.3333 3.73073 21.3333H12.2641C12.6374 21.3333 12.8241 21.3333 12.9667 21.406C13.0922 21.4699 13.1942 21.5719 13.2581 21.6973C13.3307 21.8399 13.3307 22.0266 13.3307 22.4V22.6667M7.9974 12L12.7686 20.3495C12.9779 20.7159 13.0826 20.8991 13.1566 21.0936C13.2222 21.2663 13.2698 21.4454 13.2985 21.6279C13.3307 21.8334 13.3307 22.0444 13.3307 22.4664V22.6667M7.9974 12L23.9974 9.33333M18.6641 20V19.7997C18.6641 19.3778 18.6641 19.1668 18.6963 18.9612C18.725 18.7787 18.7725 18.5996 18.8382 18.427C18.9122 18.2325 19.0169 18.0493 19.2262 17.6829L23.9974 9.33333M18.6641 20C18.6641 22.9455 21.0519 25.3333 23.9974 25.3333C26.9429 25.3333 29.3307 22.9455 29.3307 20M18.6641 20V19.7333C18.6641 19.36 18.6641 19.1733 18.7367 19.0307C18.8006 18.9052 18.9026 18.8032 19.0281 18.7393C19.1707 18.6667 19.3574 18.6667 19.7307 18.6667H28.2641C28.6374 18.6667 28.8241 18.6667 28.9667 18.7393C29.0922 18.8032 29.1942 18.9052 29.2581 19.0307C29.3307 19.1733 29.3307 19.36 29.3307 19.7333V20M23.9974 9.33333L28.7686 17.6829C28.9779 18.0493 29.0826 18.2325 29.1566 18.427C29.2222 18.5996 29.2698 18.7787 29.2985 18.9612C29.3307 19.1668 29.3307 19.3778 29.3307 19.7997V20M15.9974 4V10.6667"
      stroke="#343131"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgScales01);
export default Memo;
