import {
  AppBar,
  Box,
  Button,
  Container,
  Toolbar,
  Typography,
} from "@mui/material";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { PropsWithChildren, useEffect, useState } from "react";
import ScrollSpy from "react-ui-scrollspy";
import productFeatureData from "src/components/Products/ProductFeature/product-feature-data";
import type { BasicTableProps } from "src/components/Products/Table/Table";
import tableData from "src/components/Products/Table/table-data";

export type ProductSpyHeaderProps = {
  identifier?: BasicTableProps["dataKey"];
  name?: string;
  packetTypeSlug?: string;
  shows: (
    | "product"
    | "productType"
    | "about"
    | "details"
    | "subscription-guide"
    | "reviews"
  )[];
};

type SubNavItemType = Omit<ProductSpyHeaderProps, "shows"> & {
  itemName: ProductSpyHeaderProps["shows"][number];
  onPress: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

function SubNavItem({ itemName, name, identifier, onPress }: SubNavItemType) {
  const { t } = useTranslation();
  const selectedTableData = identifier && tableData[identifier];

  switch (itemName) {
    case "product": {
      return name ? (
        <a onClick={onPress} href="#product" data-scrollspy-offset={-220}>
          <div data-to-scrollspy-id="product">
            <Typography variant="desktopSubtitle2" color="text.secondary">
              {name}
            </Typography>
          </div>
        </a>
      ) : null;
    }

    case "productType": {
      return identifier && selectedTableData ? (
        <a onClick={onPress} href="#product-types" data-scrollspy-offset={-50}>
          <div data-to-scrollspy-id="product-types">
            <Typography variant="desktopSubtitle2" color="text.secondary">
              {t(`product:${selectedTableData.title}`)}
            </Typography>
          </div>
        </a>
      ) : null;
    }

    case "about": {
      return identifier ? (
        <a onClick={onPress} href="#about">
          <div data-to-scrollspy-id="about">
            <Typography variant="desktopSubtitle2" color="text.secondary">
              {t(productFeatureData[identifier]?.title)}
            </Typography>
          </div>
        </a>
      ) : null;
    }

    case "details": {
      return (
        <a onClick={onPress} href="#details" data-scrollspy-offset={-40}>
          <div data-to-scrollspy-id="details">
            <Typography variant="desktopSubtitle2" color="text.secondary">
              {t("product:what-you-need-to-know")}
            </Typography>
          </div>
        </a>
      );
    }

    case "subscription-guide": {
      return (
        <a
          onClick={onPress}
          href="#subscription-guide"
          data-scrollspy-offset={-72}
        >
          <div data-to-scrollspy-id="subscription-guide">
            <Typography variant="desktopSubtitle2" color="text.secondary">
              {t("membershipGuide.title")}
            </Typography>
          </div>
        </a>
      );
    }

    case "reviews": {
      return (
        <a onClick={onPress} href="#reviews" data-scrollspy-offset={-30}>
          <div data-to-scrollspy-id="reviews">
            <Typography variant="desktopSubtitle2" color="text.secondary">
              {t("product:product-testimonials.title")}
            </Typography>
          </div>
        </a>
      );
    }

    default:
      return null;
  }
}

export function ProductSpyHeader({
  children,
  identifier,
  name,
  packetTypeSlug,
  shows,
}: PropsWithChildren<ProductSpyHeaderProps>) {
  const { t } = useTranslation();

  const onPress = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const idSelector = e.currentTarget.href.split("#");

    const offset = Number(e.currentTarget.dataset.scrollspyOffset) || 0;
    const target = window?.document?.getElementById(idSelector[1]);

    if (target) {
      const calculatedYPoint =
        target.getBoundingClientRect().top + window.pageYOffset + offset;
      window?.scrollTo({ behavior: "smooth", top: calculatedYPoint });
    }
  };

  const [showSubHeader, setShowSubHeader] = useState(false);
  const handleScroll = () => {
    if (window.scrollY >= 80) setShowSubHeader(true);
    else setShowSubHeader(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <AppBar
        position={"fixed"}
        sx={{
          display: showSubHeader ? "block" : "none",
          top: 0,
          bgcolor: "background.paper",
          boxShadow:
            "0px 1px 3px rgba(0, 0, 0, 0.03), 0px 5px 15px rgba(0, 0, 0, 0.05)",
          color: "common.black",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{
              ".active-scroll-spy": {
                ".MuiTypography-root": {
                  lineHeight: "180%",
                },
                borderBottom: "2px solid #343131",
                span: { color: "primary.main" },
              },
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                whiteSpace: "nowrap",
                overflowX: "auto",
                paddingY: { xs: 2, md: 0 },
              }}
            >
              {shows.map((item) => (
                <SubNavItem
                  itemName={item}
                  onPress={onPress}
                  name={name}
                  identifier={identifier}
                  key={item}
                />
              ))}
            </Box>

            {!!packetTypeSlug && (
              <Link href={`/packets/${packetTypeSlug}`}>
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <Button variant="contained">
                    {t("product:view-packets")}
                  </Button>
                </Box>
              </Link>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <ScrollSpy
        updateHistoryStack={false}
        // useBoxMethod={true}
        scrollThrottle={80}
      >
        {children}
      </ScrollSpy>
    </>
  );
}
