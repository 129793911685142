const data = {
  pads: {
    title: "product:product-feature.pads.title",
    items: [
      {
        title: "product:product-feature.pads.one.title",
        description: "product:product-feature.pads.one.description",
      },
      {
        title: "product:product-feature.pads.two.title",
        description: "product:product-feature.pads.two.description",
      },
      {
        title: "product:product-feature.pads.three.title",
        description: "product:product-feature.pads.three.description",
      },
      {
        title: "product:product-feature.pads.four.title",
        description: "product:product-feature.pads.four.description",
      },
      {
        title: "product:product-feature.pads.five.title",
        description: "product:product-feature.pads.five.description",
      },
    ],
    pointersPosition: [
      { left: "44%", top: "20%" },
      { left: "48%", top: "35%" },
      { right: "45%", top: "45%" },
      { right: "55%", bottom: "24%" },
      { right: "50%", bottom: "35%" },
    ],
  },
  pantyliner: {
    title: "product:product-feature.pantyliner.title",
    items: [
      {
        title: "product:product-feature.pantyliner.one.title",
        description: "product:product-feature.pantyliner.one.description",
      },
      {
        title: "product:product-feature.pantyliner.two.title",
        description: "product:product-feature.pantyliner.two.description",
      },
      {
        title: "product:product-feature.pantyliner.three.title",
        description: "product:product-feature.pantyliner.three.description",
      },
    ],
    pointersPosition: [
      { left: "25%", top: "40%" },
      { left: "43%", top: "47%" },
      { right: "25%", top: "45%" },
    ],
  },
  tampons: {
    title: "product:product-feature.tampons.title",
    items: [
      {
        title: "product:product-feature.tampons.one.title",
        description: "product:product-feature.tampons.one.description",
      },
      {
        title: "product:product-feature.tampons.two.title",
        description: "product:product-feature.tampons.two.description",
      },
      {
        title: "product:product-feature.tampons.three.title",
        description: "product:product-feature.tampons.three.description",
      },
      {
        title: "product:product-feature.tampons.four.title",
        description: "product:product-feature.tampons.four.description",
      },
    ],
    pointersPosition: [
      { left: "30%", top: "20%" },
      { right: "30%", top: "60%" },
      { right: "40%", top: "25%" },
      { right: "25%", bottom: "15%" },
    ],
  },
  cup: {
    title: "product:product-feature.cup.title",
    items: [
      {
        title: "product:product-feature.cup.one.title",
        description: "product:product-feature.cup.one.description",
      },
      {
        title: "product:product-feature.cup.two.title",
        description: "product:product-feature.cup.two.description",
      },
      {
        title: "product:product-feature.cup.three.title",
        description: "product:product-feature.cup.three.description",
      },
      {
        title: "product:product-feature.cup.four.title",
        description: "product:product-feature.cup.four.description",
      },
    ],
    pointersPosition: [
      { left: "35%", top: "24%" },
      { left: "34%", top: "55%" },
      { right: "34%", top: "30%" },
      { right: "40%", bottom: "25%" },
    ],
  },
  firstperiod: {
    title: "product:product-feature.firstperiod.title",
    items: [
      {
        title: "product:product-feature.firstperiod.one.title",
        description: "product:product-feature.firstperiod.one.description",
      },
      {
        title: "product:product-feature.firstperiod.two.title",
        description: "product:product-feature.firstperiod.two.description",
      },
      {
        title: "product:product-feature.firstperiod.three.title",
        description: "product:product-feature.firstperiod.three.description",
      },
      {
        title: "product:product-feature.firstperiod.four.title",
        description: "product:product-feature.firstperiod.four.description",
      },
      {
        title: "product:product-feature.firstperiod.five.title",
        description: "product:product-feature.firstperiod.five.description",
      },
    ],
    pointersPosition: [
      { left: "44%", top: "20%" },
      { left: "48%", top: "35%" },
      { right: "45%", top: "45%" },
      { right: "55%", bottom: "24%" },
      { right: "50%", bottom: "35%" },
    ],
  },
  trial: {
    title: "product:product-feature.trial.title",
    items: [
      {
        title: "product:product-feature.trial.one.title",
        description: "product:product-feature.trial.one.description",
      },
      {
        title: "product:product-feature.trial.two.title",
        description: "product:product-feature.trial.two.description",
      },
      {
        title: "product:product-feature.trial.three.title",
        description: "product:product-feature.trial.three.description",
      },
      {
        title: "product:product-feature.trial.four.title",
        description: "product:product-feature.trial.four.description",
      },
      {
        title: "product:product-feature.trial.five.title",
        description: "product:product-feature.trial.five.description",
      },
    ],
    pointersPosition: [
      { left: "44%", top: "20%" },
      { left: "48%", top: "35%" },
      { right: "45%", top: "45%" },
      { right: "55%", bottom: "24%" },
      { right: "50%", bottom: "35%" },
    ],
  },
  donation: {
    title: "product:product-feature.donation.title",
    items: [
      {
        title: "product:product-feature.donation.one.title",
        description: "product:product-feature.donation.one.description",
      },
      {
        title: "product:product-feature.donation.two.title",
        description: "product:product-feature.donation.two.description",
      },
      {
        title: "product:product-feature.donation.three.title",
        description: "product:product-feature.donation.three.description",
      },
      {
        title: "product:product-feature.donation.four.title",
        description: "product:product-feature.donation.four.description",
      },
      {
        title: "product:product-feature.donation.five.title",
        description: "product:product-feature.donation.five.description",
      },
    ],
    pointersPosition: [
      { left: "44%", top: "20%" },
      { left: "48%", top: "35%" },
      { right: "45%", top: "45%" },
      { right: "55%", bottom: "24%" },
      { right: "50%", bottom: "35%" },
    ],
  },
};

export default data;
