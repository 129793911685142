import { Plus, XClose } from "@beije/react-icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import { Theme, SxProps } from "@mui/material/styles";
import { useState } from "react";

type AccordionProps = {
  items: Array<{
    _id: string;
    question: string;
    answer: string;
  }>;
};

export const Accordions = ({ items }: AccordionProps) => {
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const toggleAcordion = (id: string) => {
    setExpandedId(id === expandedId ? null : id);
  };

  const wrapperStyle: SxProps = () => ({
    paddingInline: 0, // remove default padding
    listStyle: "none", // remove default list style
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    "& .MuiCollapse-root": {
      width: "100%",
    },
  });

  const accordionStyle: SxProps = () => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  });

  const answerStyle: SxProps<Theme> = (theme) => ({
    width: "100%",
    background: theme.palette.background.white,
    padding: theme.spacing(4),
    borderRadius: "8px",
    mb: 3,
  });

  const expandBoxStyle: SxProps = () => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingY: 2.5,
    gap: 1.5,
    width: "100%",
  });

  return (
    <Box component="ul" sx={wrapperStyle}>
      {items.map((item) => (
        <Box component="li" key={item._id} sx={{ width: "100%" }}>
          <Divider />
          <Accordion
            sx={accordionStyle}
            expanded={item._id === expandedId}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary
              sx={{
                width: "100%",
              }}
              expandIcon={
                <Box
                  sx={{
                    paddingY: 1.5,
                  }}
                >
                  {item._id === expandedId ? <XClose /> : <Plus />}
                </Box>
              }
              aria-controls={`question-${item._id}-content`}
              id={`question-${item._id}-header`}
              onClick={() => toggleAcordion(item._id)}
            >
              <Box sx={expandBoxStyle}>
                <Typography
                  variant={
                    item._id === expandedId
                      ? "desktopSubtitle1"
                      : "desktopBody1"
                  }
                >
                  {item.question}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={answerStyle}>
              <Typography variant="desktopBody1">{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
    </Box>
  );
};
