import { Ruler, Drop, Scales01 } from "@beije/react-icons";
import { Typography, Box, Divider } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { useTranslation } from "next-i18next";

type MobileTableProps = {
  selectedTableData: { title: string; data: any[][] };
};
const MobileTable = ({ selectedTableData }: MobileTableProps) => {
  const theme: Theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box textAlign="center">
      <Typography component="h4" variant="desktopH4" mb={5}>
        {t(`product:${selectedTableData.title}`)}
      </Typography>
      {selectedTableData.data.map((parentItem, parentIndex) => (
        <Box key={parentIndex}>
          <Box>
            <Typography
              variant="desktopH5"
              color={theme.palette.text.secondary}
            >
              {t(
                `product:${
                  Array.isArray(parentItem[0])
                    ? parentItem[0][0]
                    : parentItem[0]
                }`
              )}
            </Typography>
            {Array.isArray(parentItem[0]) && (
              <>
                <br />
                <Typography
                  variant="desktopCaption"
                  color={theme.palette.text.secondary}
                >
                  {t(`product:${parentItem[0][1]}`)}
                </Typography>
              </>
            )}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            py={2}
          >
            <Ruler />
            <Drop />
            <Scales01 />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-around">
            {parentItem.map(
              (item, index) =>
                index !== 0 && (
                  <Typography
                    key={index}
                    component="p"
                    variant="desktopBody1"
                    color={theme.palette.text.secondary}
                    ml={-2}
                  >
                    {item}
                  </Typography>
                )
            )}
          </Box>
          {parentIndex < selectedTableData.data.length - 1 && (
            <Divider sx={{ my: 2 }} />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default MobileTable;
