import { Box, Container, Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Link from "next/link";
import { useTranslation } from "next-i18next";

import CupImage from "public/images/product/cup.png";
import PadImage from "public/images/product/pad.png";
import PantylinerImage from "public/images/product/pantyliner.png";
import TamponImage from "public/images/product/tampon.png";
import { useMemo } from "react";
import PreviewProduct from "src/components/Products/PreviewProduct";

type PreviewProductProps = {
  title: string;
  desc?: string;
  except?: string;
};

const PreviewProducts = ({ title, desc, except }: PreviewProductProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up("lg"));

  const productToShow = useMemo(() => {
    const allProducts = [
      {
        id: 1,
        key: "pads",
        title: t("home:products.pad"),
        link: "/product/pads",
        image: PadImage,
      },
      {
        id: 2,
        key: "pantyliner",
        title: t("home:products.pantyliner"),
        link: "/product/pantyliner",
        image: PantylinerImage,
      },
      {
        id: 3,
        key: "tampons",
        title: t("home:products.tampon"),
        link: "/product/tampons",
        image: TamponImage,
      },
      {
        id: 4,
        key: "cup",
        title: t("home:products.cup"),
        link: "/product/cup",
        image: CupImage,
      },
    ];
    return except
      ? allProducts.filter((item) => item.key !== except)
      : allProducts;
  }, [except, t]);

  return (
    <Container maxWidth="lg" sx={{ paddingTop: 12, paddingBottom: 7 }}>
      <Box>
        <Grid
          container
          spacing={3}
          mb={9}
          sx={{ textAlign: !breakpoint ? "center" : undefined }}
        >
          <Grid item md={6} sm={12}>
            <Typography component="h3" variant="desktopH3">
              {title}
            </Typography>
          </Grid>
          <Grid item md={6} sm={12}>
            <Typography component="p" variant="desktopBody1">
              {desc}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            overflowX: !breakpoint ? "scroll" : undefined,
            flexDirection: !breakpoint ? "row" : undefined,
            flexWrap: !breakpoint ? "nowrap" : undefined,
          }}
        >
          {productToShow.map((item) => (
            <Grid key={item.id} item md={except ? 4 : 3} sm={12}>
              <Link href={item.link}>
                <PreviewProduct
                  name={t(`home:products:${item.key}`)}
                  image={item.image}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default PreviewProducts;
