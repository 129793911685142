import Image from "next/image";
import { FC } from "react";

import reasonStyle from "./Reason.module.scss";

type ReasonProps = {
  icon: string;
  title: string;
  desc: string;
};

const Reason: FC<ReasonProps> = ({ icon, title, desc }) => (
  <div className={reasonStyle.container}>
    <Image
      src={icon}
      alt={title}
      width={90}
      height={90}
      style={{
        maxWidth: "100%",
        height: "auto",
      }}
    />
    <h3 className={reasonStyle.title}>{title}</h3>
    <p className={reasonStyle.desc}>{desc}</p>
  </div>
);

export default Reason;
