import { Ruler, Drop, Scales01 } from "@beije/react-icons";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { useTranslation } from "next-i18next";

type DesktopTableProps = {
  selectedTableData: { title: string; data: any[][] };
};
const DesktopTable = ({ selectedTableData }: DesktopTableProps) => {
  const theme: Theme = useTheme();
  const { t } = useTranslation();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="desktopH4">
              {t(`product:${selectedTableData.title}`)}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Ruler />
          </TableCell>
          <TableCell align="center">
            <Drop />
          </TableCell>
          <TableCell align="center">
            <Scales01 />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {selectedTableData.data.map((parentItem, parentIndex) => (
          <TableRow
            key={parentIndex}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            {parentItem.map((item, index) => (
              <TableCell key={index} align={index !== 0 ? "center" : "left"}>
                {Array.isArray(item) ? (
                  <Box>
                    <Typography
                      variant={index === 0 ? "desktopH5" : "desktopBody1"}
                      color={theme.palette.text.secondary}
                    >
                      {t(`product:${item[0]}`)}
                    </Typography>
                    <br />
                    <Typography
                      variant={index === 0 ? "desktopCaption" : "desktopBody1"}
                      color={theme.palette.text.secondary}
                    >
                      {t(`product:${item[1]}`)}
                    </Typography>
                  </Box>
                ) : (
                  <Typography
                    variant={index === 0 ? "desktopH5" : "desktopBody1"}
                    color={theme.palette.text.secondary}
                  >
                    {index === 0 ? t(`product:${item}`) : item}
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default DesktopTable;
