import { useMediaQuery } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";

import DesktopTable from "./DesktopTable";
import MobileTable from "./MobileTable";
import tableData from "./table-data";

export type BasicTableProps = {
  dataKey: "pads" | "pantyliner" | "tampons" | "cup";
};
const BasicTable = ({ dataKey }: BasicTableProps) => {
  const theme: Theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.down("md"));

  const selectedTableData = tableData[dataKey];
  return breakpoint ? (
    <MobileTable selectedTableData={selectedTableData} />
  ) : (
    <DesktopTable selectedTableData={selectedTableData} />
  );
};

export default BasicTable;
