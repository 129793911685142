import { useMediaQuery, Box, Container, Typography, Grid } from "@mui/material";
import { Theme, useTheme, alpha } from "@mui/material/styles";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { useState } from "react";

import { Pointer } from "src/components/Common";

import type { ProductTypePageIdentifier } from "src/types/Product";

import productFeatureData from "./product-feature-data";

type ProductFeatureProps = {
  dataKeys: Array<ProductTypePageIdentifier>;
};
const ProductFeature = ({ dataKeys }: ProductFeatureProps) => {
  const theme: Theme = useTheme();
  const { t } = useTranslation();
  const breakpoint = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedDataKey, setSelectedDataKey] = useState(dataKeys[0]);

  const componentTheme = {
    pads: {
      type: "light",
      background: theme.palette.common.red.main,
    },
    pantyliner: {
      type: "dark",
      background: theme.palette.common.orange.main,
    },
    tampons: {
      type: "light",
      background: theme.palette.common.purple.main,
    },
    cup: {
      type: "light",
      background: theme.palette.common.beije.secondary.green.main,
    },
    firstperiod: {
      type: "light",
      background: theme.palette.common.red.main,
    },
    trial: {
      type: "light",
      background: theme.palette.common.red.main,
    },
    donation: {
      type: "light",
      background: theme.palette.common.red.main,
    },
  };

  const customTheme = {
    light: {
      primary: theme.palette.primary.contrastText,
      secondary: theme.palette.primary.contrastText,
      disabled: alpha(theme.palette.primary.contrastText, 0.7),
    },
    dark: {
      primary: theme.palette.primary.main,
      secondary: theme.palette.text.primary,
      disabled: theme.palette.text.secondary,
    },
  };
  const themeType = componentTheme[selectedDataKey]?.type;
  const selectedTheme = customTheme[themeType as "dark" | "light"];
  const selectedBackground = componentTheme[selectedDataKey]?.background;

  const [selectedItem, setSelectedItem] = useState<number>(0);

  const selectedData = productFeatureData[selectedDataKey];

  const pointerPositionValues = selectedData?.pointersPosition;

  return (
    <Box id="about" py={9} bgcolor={selectedBackground}>
      <Container>
        <Box display="flex" justifyContent="center" gap={2} mb={8}>
          {dataKeys.map((item, index) => (
            <Typography
              key={index}
              component="h2"
              variant={
                breakpoint || dataKeys?.length > 2 ? "mobileH2" : "desktopH2"
              }
              textAlign="center"
              color={
                item === selectedDataKey
                  ? selectedTheme.primary
                  : selectedTheme.disabled
              }
              borderBottom={
                item === selectedDataKey && dataKeys?.length > 1
                  ? `2px solid ${selectedTheme.primary}`
                  : 0
              }
              pb={2}
              sx={{ cursor: "pointer" }}
              onClick={() => setSelectedDataKey(item)}
            >
              {t(productFeatureData[item]?.title)}
            </Typography>
          ))}
        </Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={6} sm={12}>
            <Box position="relative">
              <Image
                src={`/images/product-feature/${selectedDataKey}.png`}
                height={560}
                width={560}
                alt={`beije ${selectedDataKey} featurs`}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              {Array.from(Array(selectedData?.items?.length).keys()).map(
                (_item, index) => (
                  <Pointer
                    key={index}
                    id={index}
                    size="big"
                    positionValues={pointerPositionValues[index]}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                  />
                )
              )}
            </Box>
          </Grid>
          <Grid item md={6} sm={12}>
            <Box ml={breakpoint ? 0 : 8}>
              {selectedData?.items?.map((item, index) => (
                <Pointer
                  key={index}
                  id={index}
                  size="small"
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  selectedTheme={selectedTheme}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ProductFeature;
