import { Box, Typography } from "@mui/material";
import { useTheme, Theme } from "@mui/material/styles";
import { orderBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { RangeInput } from "src/components/Common";
import { OUT_OF_STOCK_PACKETS_SUB_TYPE } from "src/constant";
import { RootState } from "src/store";
import { useGetProductsQuery } from "src/store/apis";
import { upsertPacketItem } from "src/store/slices";

type ProductSelectorProps = {
  subTypeId: string;
  productTypeId: string;
  subTypeLabel: string;
  value?: number;
};

export const ProductSelector = ({
  subTypeId,
  productTypeId,
  subTypeLabel,
  value,
}: ProductSelectorProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const { data: stateProducts } = useGetProductsQuery();

  const isEditSubscriptionAction = useSelector(
    (state: RootState) => state.CustomPacket.action === "EDIT_SUBSCRIPTION"
  );

  const sortedProductsOfSubtype = useMemo(() => {
    if (!stateProducts) return [];

    const res = stateProducts.filter(
      (product) => product.subTypeId === subTypeId
    );

    return orderBy(res, "productSize");
  }, [stateProducts, subTypeId]);

  const minProductSizeInProductType = sortedProductsOfSubtype?.[0].productSize;
  const maxProductSizeInProductType =
    sortedProductsOfSubtype?.[sortedProductsOfSubtype.length - 1].productSize;

  const handleInputChange = (_: Event, sliderValue: number | number[]) => {
    dispatch(
      upsertPacketItem({
        _productSubType: subTypeId,
        _productType: productTypeId,
        userSelectedQuantity: sliderValue as number,
      })
    );
  };

  const memoValue = useMemo(() => value, []);

  const showOutOfStockMessageCondition =
    (OUT_OF_STOCK_PACKETS_SUB_TYPE.includes(subTypeId) &&
      !isEditSubscriptionAction) ||
    (OUT_OF_STOCK_PACKETS_SUB_TYPE.includes(subTypeId) &&
      isEditSubscriptionAction &&
      !memoValue);

  return (
    <Box width="100%">
      <Typography variant="desktopBody1" display="inline-block" height="42px">
        {subTypeLabel}
      </Typography>

      {showOutOfStockMessageCondition ? (
        <Typography
          component="p"
          variant="desktopSubtitle1"
          color={theme.palette.common.red.main}
          mb={2}
        >
          {t("common:out-of-stock-message", {
            packetName: subTypeLabel,
          })}
        </Typography>
      ) : (
        <RangeInput
          value={value ?? 0}
          defaultValue={value ?? 0}
          onChange={handleInputChange}
          min={0}
          step={minProductSizeInProductType}
          max={
            OUT_OF_STOCK_PACKETS_SUB_TYPE.includes(subTypeId) &&
            isEditSubscriptionAction
              ? memoValue
              : maxProductSizeInProductType * 2
          }
        />
      )}
    </Box>
  );
};
