import {
  useMediaQuery,
  Container,
  Box,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Image from "next/image";
import Link from "next/link";
import { useTranslation } from "next-i18next";

import productMaterialData from "./product-material-data";

type ProductMaterial = {
  dataKey: "pads" | "tampons" | "cup";
};

const ProductMaterial = ({ dataKey }: ProductMaterial) => {
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();

  const selectedData = productMaterialData[dataKey];

  return (
    <Box
      bgcolor={theme.palette.background.paper}
      paddingY={12}
      position="relative"
    >
      <Container>
        <Grid
          container
          spacing={!breakpoint ? 3 : 8}
          alignItems="center"
          flexDirection={!breakpoint ? "column-reverse" : "row"}
        >
          <Grid item md={6} sm={12} textAlign={!breakpoint ? "center" : "left"}>
            <Box color={theme.palette.text.primary}>
              <Typography component="h3" variant="desktopH3" mb={3}>
                {t(selectedData.title)}
              </Typography>
              <Typography component="p" variant="desktopBody1" mb={2}>
                {t(selectedData.description)}
              </Typography>
              <Link href={`/know-us/all-materials`}>
                <Button variant="contained" sx={{ mt: 5 }}>
                  {t("product:product-materials.button")}
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item md={6} sm={12}>
            <Box component="section" position="relative">
              <Image
                src={selectedData.image.src}
                width={selectedData.image.width}
                height={selectedData.image.height}
                alt="Materials"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ProductMaterial;
