import MedicalSiliconeImage from "/public/images/materials/medical-silicone.webp";
import OrganicBambooFiberImage from "/public/images/materials/organic-bamboo-fiber.webp";
import OrganicCottonImage from "/public/images/materials/organic-cotton.webp";

const data = {
  pads: {
    title: "product:product-materials.pads.title",
    description: "product:product-materials.pads.description",
    image: OrganicBambooFiberImage,
  },
  tampons: {
    title: "product:product-materials.tampons.title",
    description: "product:product-materials.tampons.description",
    image: OrganicCottonImage,
  },
  cup: {
    title: "product:product-materials.cup.title",
    description: "product:product-materials.cup.description",
    image: MedicalSiliconeImage,
  },
};

export default data;
