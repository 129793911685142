import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Loading } from "src/components/Common";
import { ProductSelector } from "src/components/Products/ProductSelector";
import type { RootState } from "src/store";
import { useGetProductSubTypesQuery } from "src/store/apis";

type ProductTypeViewerProps = {
  productTypeId: string;
};

const ProductTypeViewer = ({ productTypeId }: ProductTypeViewerProps) => {
  const { i18n } = useTranslation();
  const { data: stateSubTypes, isLoading } = useGetProductSubTypesQuery();
  const packetItems = useSelector(
    (state: RootState) => state.CustomPacket.packet.items
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box width="100%">
      {stateSubTypes
        ?.filter((subType) => subType.typeId === productTypeId)
        .sort(({ sortOrder: a }, { sortOrder: b }) => a - b)
        .map((subType) => (
          <ProductSelector
            key={subType.id}
            subTypeId={subType.id}
            productTypeId={subType.typeId}
            subTypeLabel={subType.name[i18n.language as "tr" | "en"]}
            value={
              packetItems.find((x) => x._productSubType === subType.id)
                ?.userSelectedQuantity
            }
          />
        ))}
    </Box>
  );
};

export default ProductTypeViewer;
