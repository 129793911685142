import { Card, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

type PreviewProductProps = {
  name: string;
  image: { src: string };
};

const PreviewProduct = ({ name, image }: PreviewProductProps) => {
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Card
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        width: !breakpoint ? 270 : "100%",
        height: 270,
        aspectRatio: 1,
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${image?.src})`,
        backgroundSize: "cover",
        cursor: "pointer",
        position: "relative",
      }}
    >
      <Typography
        sx={{ position: "absolute", left: 24, bottom: 24 }}
        component="p"
        variant="mobileH1"
        color={theme.palette.primary.contrastText}
      >
        {name}
      </Typography>
    </Card>
  );
};

export default PreviewProduct;
