import { Box, Skeleton, useMediaQuery, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function ProductPageSkeleton() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box py={8}>
      <Grid container spacing={8}>
        <Grid item xs={12} md={6}>
          <Skeleton width="100%" height="440px" sx={{ transform: "unset" }} />
        </Grid>
        <Grid item xs={12} md={6} height="100%">
          <Skeleton variant="text" width="60%" height="40px" />
          <Skeleton variant="text" width="60%" sx={{ mt: 1, mb: 3 }} />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="80%" />
          <Box
            mt={4}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={8}
          >
            <Skeleton width="100%" height="80px" />
            <Skeleton width="100%" height="80px" />
            <Skeleton width="100%" height="80px" />
          </Box>
          <Skeleton sx={{ mt: 4 }} width="100%" height="80px" />
        </Grid>
      </Grid>
    </Box>
  );
}
