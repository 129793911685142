import { API_SERVICES } from "src/constant";
import { JSendApiResponse } from "src/interfaces/API";
import { FaqItem, FaqTypeItem } from "src/types/Faq";

import { BaseAPI } from "./config";

export const FaqAPI = BaseAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getFaqs: builder.query<
      JSendApiResponse<FaqItem[]>,
      { filter?: string; limit: number }
    >({
      query: ({ filter, limit }) => API_SERVICES.faq.query({ filter, limit }),
    }),
    getFaqTypes: builder.query<JSendApiResponse<FaqTypeItem[]>, void>({
      query: () => API_SERVICES.faq.faqTypes(),
    }),
    getFaqTypeBySlug: builder.query<
      JSendApiResponse<FaqTypeItem>,
      { slug: string }
    >({
      query: ({ slug }) => API_SERVICES.faq.faqTypeSlug(slug),
    }),
  }),
});

export const {
  useGetFaqsQuery,
  useGetFaqTypesQuery,
  useGetFaqTypeBySlugQuery,
} = FaqAPI;
